<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.157581 7.11058C-0.113779 7.66791 -0.0236004 8.33922 0.384564 8.80029L9.05318 18.5925C9.29196 18.8622 9.71292 18.8622 9.95169 18.5925L18.6203 8.80029C19.0285 8.33922 19.1187 7.66791 18.8473 7.11058C18.7504 6.91155 18.6969 6.80175 18.6403 6.69362C18.4531 6.33625 18.2311 5.99708 16.2914 2.51555C16.1148 2.19847 15.7794 2 15.4164 2H3.58848C3.22551 2 2.8901 2.19847 2.71344 2.51555C0.773739 5.99708 0.551787 6.33625 0.364588 6.69362C0.307946 6.80175 0.254485 6.91155 0.157581 7.11058ZM4.75227 5.33975C4.95716 5.01191 4.8575 4.58005 4.52966 4.37515C4.20183 4.17025 3.76996 4.26992 3.56507 4.59775L1.78382 7.44776C1.61444 7.71877 1.65011 8.07009 1.87052 8.30152L4.24552 10.7953C4.51214 11.0752 4.95522 11.086 5.23518 10.8194C5.51513 10.5528 5.52593 10.1097 5.25931 9.82974L3.25883 7.72925L4.75227 5.33975Z"
      fill="inherit"
    />
  </svg>
</template>

<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1194_1660)">
      <path
        d="M15.2478 15.6239C15.0047 15.6239 14.7611 15.5358 14.5686 15.3576C14.1634 14.9824 14.1391 14.3497 14.5143 13.9445C15.2578 13.1416 15.6672 12.0964 15.6672 11.0014C15.6672 9.90647 15.2578 8.86126 14.5143 8.05834C14.1391 7.65309 14.1634 7.02038 14.5686 6.64518C14.9739 6.26988 15.6066 6.2943 15.9818 6.69947C17.0686 7.87322 17.6672 9.40105 17.6672 11.0014C17.6672 12.6018 17.0686 14.1296 15.9818 15.3034C15.7847 15.5162 15.5166 15.6239 15.2478 15.6239Z"
        fill="white"
      />
      <path
        d="M17.9258 18.6084C17.6694 18.6084 17.413 18.5104 17.2177 18.3145C16.8277 17.9235 16.8286 17.2903 17.2196 16.9004C18.7984 15.3259 19.6679 13.2306 19.6679 11.0005C19.6679 8.77042 18.7984 6.67513 17.2196 5.10063C16.8286 4.71067 16.8277 4.07746 17.2177 3.68646C17.6077 3.29534 18.2408 3.29455 18.6319 3.68451C20.5897 5.63696 21.6679 8.23517 21.6679 11.0005C21.6679 13.7658 20.5897 16.364 18.6319 18.3165C18.4368 18.5111 18.1813 18.6084 17.9258 18.6084Z"
        fill="white"
      />
      <path
        d="M4.45898 7H2.16732C1.15478 7 0.333984 7.82079 0.333984 8.83333V13.1667C0.333984 14.1792 1.15478 15 2.16732 15H4.45898C4.57403 15 4.66732 14.9067 4.66732 14.7917V7.20833C4.66732 7.09329 4.57403 7 4.45898 7Z"
        fill="white"
      />
      <path
        d="M12 2.66797H11C10.7805 2.66797 10.567 2.74022 10.3926 2.87359L6.08179 6.17009C6.03025 6.20951 6 6.27068 6 6.33559V15.6671C6 15.7319 6.03025 15.7931 6.08179 15.8326L10.3926 19.129C10.567 19.2624 10.7805 19.3347 11 19.3347H12C12.5523 19.3347 13 18.887 13 18.3347V3.66797C13 3.11568 12.5523 2.66797 12 2.66797Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1194_1660">
        <rect
          width="21.3333"
          height="21.3333"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <div
    ref="menu"
    :class="[$style.menu, !userStore.userIsAuthorized && $style['mobile-fix']]"
    @click.self="coreStore.hideMenuHeader"
  >
    <div :class="$style['inner-container']">
      <CloseButton />
      <UserData v-if="userStore.userIsAuthorized" />
      <Controls />
    </div>
  </div>
</template>

<script setup lang="ts">
import CloseButton from "./Menu/CloseButton.vue";
import UserData from "./Menu/UserData.vue";
import Controls from "./Menu/Controls.vue";

let timer: undefined | ReturnType<typeof setTimeout> = undefined;
const userStore = useUserStore();
const coreStore = useCoreStore();
const menu = ref<null | HTMLElement>(null);

const handleDocumentClick = (e: MouseEvent) => {
  const target = e.target as Node | null;
  if (target && !menu.value?.contains(target)) {
    coreStore.hideLanguageSubmenu();
    coreStore.hideMenuHeader();
  }
};

onMounted(() => {
  timer = setTimeout(
    () => document.addEventListener("click", handleDocumentClick),
    10,
  );
});

onBeforeUnmount(() => {
  clearTimeout(timer);
  document.removeEventListener("click", handleDocumentClick);
});
</script>

<style lang="scss" module>
.menu {
  position: absolute;
  right: 56px;
  top: calc(100% + 5px);
  color: $white-2;
  background-color: var(--general-transparent-dark-35);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  transition: all 0.1s linear;
  border-radius: 20px;

  @media screen and (max-width: 1300px) {
    right: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100dvh;
    background-color: rgba($color: #000, $alpha: 0.35);
  }
}

.mobile-fix {
  @media screen and (min-width: 1301px) {
    display: none;
  }
}

.inner-container {
  display: flex;
  flex-direction: column;
  width: 311px;
  border-radius: $br15;
  background: var(--general-transparent-dark-75);
  backdrop-filter: blur(40px);
  box-shadow: 2px 4px 10px 0px rgba(20, 22, 26, 0.3);
  padding-bottom: 20px;

  @media screen and (max-width: 1300px) {
    width: 320px;
    height: 100%;
    border-radius: 0;
    overflow: auto;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.hidden-on-pc {
  @media screen and (min-width: 1301px) {
    display: none;
  }
}
</style>

import type { Ref } from "vue";
import { onMounted, onUnmounted, ref } from "vue";

/**
 * Возвращает флаг, который показывает, нужно ли переключить
 * фиксированное позиционированием в абсолютное.
 *
 * @param {object} elementRef - реф элемента.
 * @param {object} bottom - реф числового значение высоты на которой должна остановится сайд панель.
 */
export default function useScrollStopPosition({
  elementRef,
  bottom,
}: {
  elementRef: Ref;
  bottom: Ref;
}) {
  const stopped = ref(false);
  let stopPosition = 0;
  const scrollHandle = () => {
    if (window.innerWidth <= 1100) return;
    const documentRect = document.body.getBoundingClientRect();
    const rect = elementRef.value?.getBoundingClientRect();
    if (rect) {
      const yBottom = documentRect.bottom - rect.bottom;
      if (!stopped.value && bottom.value && yBottom <= bottom.value) {
        stopped.value = true;
        stopPosition = window.scrollY - (bottom.value - yBottom);
        return;
      }

      if (stopPosition > window.scrollY) {
        stopped.value = false;
      }
    }
  };

  if (isClient()) {
    onMounted(() => {
      setTimeout(() => {
        window.addEventListener("scroll", scrollHandle);
        scrollHandle();
      }, 1100);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", scrollHandle);
    });
  }

  return stopped;
}

<template>
  <div :class="$style['default-layout']">
    <Header />
    <RibbonPlaceholder v-if="route.meta.ribbonVisible">
      <ClientOnly>
        <Ribbon />
      </ClientOnly>
    </RibbonPlaceholder>
    <ClientOnly>
      <LazyChat v-if="chatIsRequired" />
      <Transition name="popup">
        <LazyPlatformSwitcher v-if="coreStore.platformSwitcherIsRequired" />
      </Transition>
      <Transition name="popup">
        <LazyPromocode v-if="promocodesStore.promocodeWindowIsRequired" />
      </Transition>
      <Transition name="popup">
        <LazyAuth v-if="userStore.authPopupIsRequired" />
      </Transition>
      <Transition name="popup">
        <LazyPhoneVerify v-if="phoneVerifyStore.verifyWindowIsRequired" />
      </Transition>
      <Transition name="popup">
        <LazyGeneralPriseModal v-if="calendarStore.lotteryPriseIsRequired" />
      </Transition>

      <Transition name="popup">
        <template v-if="exchangerEventStore.showRewardPopup">
          <LazyExchangerRewardPopup v-if="exchangerEventEnable" />
          <LazyBossBattleRewardPopup v-else-if="bossBattleEnable" />
        </template>
      </Transition>
      <Transition name="popup">
        <LazyNotifications
          v-if="notificationsStore.notificationsWindowIsRequired"
        />
      </Transition>
      <LazySliderPopup
        v-model="userStore.onboardingPopupIsRequired"
        media-type="video"
        :steps="useOnboardingData()"
        :inverted="true"
        :finish-btn-text="t('onboarding.buttons.close_btn')"
        data-test="onboarding"
      />
      <Transition name="popup">
        <LazyNameRegister v-if="userStore.nameRegisterPopupIsRequired" />
      </Transition>
      <LazyBottomSheet
        v-if="coreStore.languageSubmenuIsRequired"
        @close="coreStore.hideLanguageSubmenu"
      >
        <SetLanguageLocales />
      </LazyBottomSheet>
    </ClientOnly>
    <SidePanel :footer-ref="footerRef" />
    <PageContent><slot /></PageContent>
    <Footer ref="footerRef" />
  </div>
</template>

<script setup lang="ts">
import PageContent from "~/components/Containers/PageContent.vue";
import LazyPlatformSwitcher from "~/components/PlatformSwitcher/PopupSwitcher.vue";
import LazyExchangerRewardPopup from "~/components/ExchangerEvent/RewardPopup.vue";
import LazyBossBattleRewardPopup from "~/components/BossBattle/RewardPopup.vue";
import LazySliderPopup from "~/components/Shared/Popup/SliderPopup.vue";
import { usePlatformSelection } from "~/composables/app/usePlatformSelection";
import { useOnboardingData } from "~/composables/app/useOnboardingData";
import { usePromocodeHandler } from "~/composables/app/usePromocodeHandler";
import { useReferralHandler } from "~/composables/app/useReferralHandler";
import { useAudioState } from "~/composables/app/useAudioState";
import { BATTLE_NAME } from "~/components/BossBattle/composables/initMainView";
import { EVENT_NAME } from "~/stores/exchanger";

const { t } = useI18n();
const route = useRoute();
const userStore = useUserStore();
const coreStore = useCoreStore();
const calendarStore = useCalendarStore();
const promocodesStore = usePromocodesStore();
const phoneVerifyStore = usePhoneVerifyStore();
const exchangerEventStore = useExchangerEventStore();
const notificationsStore = useNotificationsStore();
const footerRef = ref<HTMLElement | null>(null);
const chatIsRequired = ref(false);

onNuxtReady(() => {
  chatIsRequired.value = true;
  usePlatformSelection();
  usePromocodeHandler();
  useReferralHandler();
  useAudioState();
});

const exchangerEventEnable = computed(() =>
  exchangerEventStore.rewardPopupData?.event?.slug.includes(EVENT_NAME),
);

const bossBattleEnable = computed(() =>
  exchangerEventStore.rewardPopupData?.event?.slug.includes(BATTLE_NAME),
);
</script>

<style lang="scss" module>
.default-layout {
  position: relative;
  padding-top: 68px; // высота Header
}
</style>

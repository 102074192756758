<template>
  <button
    :class="$style['notification-button']"
    @click="notificationsStore.showNotificationsWindow"
  >
    <IconBell />
    <span
      v-if="notificationsStore.hasUnreadNotifications"
      :class="$style.dot"
    />
    <slot />
  </button>
</template>

<script setup lang="ts">
const notificationsStore = useNotificationsStore();
</script>

<style lang="scss" module>
.notification-button {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  &:hover {
    svg {
      fill: lighten($color: $white-1, $amount: 5%);
    }
  }

  svg {
    width: 15px;
    fill: var(--general-grey);
    transition: all 0.25s;
  }
}

.dot {
  position: absolute;
  left: 18px;
  top: 15px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $red-1;
}
</style>

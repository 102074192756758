import slide1 from "~/assets/Onboarding/01.mp4";
import slide2 from "~/assets/Onboarding/02.mp4";
import slide3 from "~/assets/Onboarding/03.mp4";
import slide1En from "~/assets/Onboarding/01_ENG.mp4";
import slide2En from "~/assets/Onboarding/02_ENG.mp4";
import slide3En from "~/assets/Onboarding/03_ENG.mp4";

export const useOnboardingData = () => {
  const { locale, t } = useI18n();
  return [
    {
      id: "1",
      name: t("onboarding.video_slides[0].name"),
      title: t("onboarding.video_slides[0].subtitle"),
      text: t("onboarding.video_slides[0].text"),
      media: locale.value === "ru" ? slide1 : slide1En,
    },
    {
      id: "2",
      name: t("onboarding.video_slides[1].name"),
      title: t("onboarding.video_slides[1].subtitle"),
      text: t("onboarding.video_slides[1].text"),
      media: locale.value === "ru" ? slide2 : slide2En,
    },
    {
      id: "3",
      name: t("onboarding.video_slides[2].name"),
      title: t("onboarding.video_slides[2].subtitle"),
      text: t("onboarding.video_slides[2].text"),
      media: locale.value === "ru" ? slide3 : slide3En,
    },
  ];
};

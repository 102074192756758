<template>
  <div :class="$style['footer-stats']">
    <StatBlock>
      <template #icon>
        <IconUser />
      </template>
      <template #name> {{ t("footer.players") }} </template>
      <template #value>
        {{ valueToShortAbbr(stats.players) }}
      </template>
    </StatBlock>
    <StatBlock>
      <template #icon>
        <IconCases />
      </template>
      <template #name> {{ t("footer.cases") }} </template>
      <template #value>
        {{ valueToShortAbbr(stats.casesOpened) }}
      </template>
    </StatBlock>
    <StatBlock>
      <template #icon>
        <IconLadder />
      </template>
      <template #name> {{ t("footer.ladder_games") }} </template>
      <template #value>
        {{ valueToShortAbbr(stats.ladderBets) }}
      </template>
    </StatBlock>
    <StatBlock>
      <template #icon>
        <IconCrash />
      </template>
      <template #name> {{ t("footer.crash_games") }} </template>
      <template #value>
        {{ valueToShortAbbr(stats.crashBets) }}
      </template>
    </StatBlock>
    <StatBlock>
      <template #icon>
        <IconSapper />
      </template>
      <template #name> {{ t("footer.sapper_games") }} </template>
      <template #value>
        {{ valueToShortAbbr(stats.minerBets) }}
      </template>
    </StatBlock>
    <StatBlock>
      <template #icon>
        <IconWheel />
      </template>
      <template #name> {{ t("footer.wheel_games") }} </template>
      <template #value>
        {{ valueToShortAbbr(stats.wheelBets) }}
      </template>
    </StatBlock>
  </div>
</template>

<script lang="ts" setup>
import type { Locales } from "~/types/Locales";
import * as Sentry from "@sentry/vue";
import StatBlock from "./Stats/StatBlock.vue";

const api = useApi();

const { t, locale } = useI18n();
const stats = reactive({
  players: 0,
  casesOpened: 0,
  ladderBets: 0,
  crashBets: 0,
  minerBets: 0,
  wheelBets: 0,
});
let interval: ReturnType<typeof setInterval> | number = 0;
const fetchData = async () => {
  try {
    const data = await api.games.overviewFooterValuesRetrieve();
    Object.assign(stats, data);
  } catch (e) {
    Sentry.captureException(e);
  }
};

onBeforeMount(async () => {
  await fetchData();
  if (isClient()) interval = setInterval(fetchData, 600000);
});

onBeforeUnmount(() => {
  clearInterval(interval);
});

function valueToShortAbbr(value: number) {
  const absValue = Math.abs(Number(value));
  if (isNaN(absValue)) return;
  const numbers = {
    billions: {
      value: 1.0e9,
      text: {
        en: "+ b",
        ru: "+ млрд",
      },
    },
    millions: {
      value: 1.0e6,
      text: {
        en: "+ mln",
        ru: "+ млн",
      },
    },
    thousands: {
      value: 1.0e3,
      text: {
        en: "+ k",
        ru: "+ тыс",
      },
    },
  };

  if (absValue >= numbers.billions.value)
    return (
      (absValue / numbers.billions.value).toFixed(0) +
      numbers.billions.text[locale.value as Locales]
    );
  if (absValue >= numbers.millions.value)
    return (
      (absValue / numbers.millions.value).toFixed(0) +
      numbers.millions.text[locale.value as Locales]
    );
  if (absValue >= numbers.thousands.value)
    return (
      (absValue / numbers.thousands.value).toFixed(0) +
      numbers.thousands.text[locale.value as Locales]
    );

  return absValue;
}
</script>

<style lang="scss" module>
.footer-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1070px;
  @media screen and (max-width: 1360px) {
    justify-content: space-between;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 42px;
  }
  @media screen and (max-width: 680px) {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, auto);
    grid-row-gap: 42px;
  }
}
</style>

<template>
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.625C0 1.35978 0.112882 1.10543 0.313814 0.917893C0.514746 0.730357 0.787268 0.625 1.07143 0.625H13.9286C14.2127 0.625 14.4853 0.730357 14.6862 0.917893C14.8871 1.10543 15 1.35978 15 1.625C15 1.89022 14.8871 2.14457 14.6862 2.33211C14.4853 2.51964 14.2127 2.625 13.9286 2.625H1.07143C0.787268 2.625 0.514746 2.51964 0.313814 2.33211C0.112882 2.14457 0 1.89022 0 1.625ZM0 6.625C0 6.35978 0.112882 6.10543 0.313814 5.91789C0.514746 5.73036 0.787268 5.625 1.07143 5.625H13.9286C14.2127 5.625 14.4853 5.73036 14.6862 5.91789C14.8871 6.10543 15 6.35978 15 6.625C15 6.89022 14.8871 7.14457 14.6862 7.33211C14.4853 7.51964 14.2127 7.625 13.9286 7.625H1.07143C0.787268 7.625 0.514746 7.51964 0.313814 7.33211C0.112882 7.14457 0 6.89022 0 6.625ZM0 11.625C0 11.3598 0.112882 11.1054 0.313814 10.9179C0.514746 10.7304 0.787268 10.625 1.07143 10.625H7.5C7.78416 10.625 8.05668 10.7304 8.25762 10.9179C8.45855 11.1054 8.57143 11.3598 8.57143 11.625C8.57143 11.8902 8.45855 12.1446 8.25762 12.3321C8.05668 12.5196 7.78416 12.625 7.5 12.625H1.07143C0.787268 12.625 0.514746 12.5196 0.313814 12.3321C0.112882 12.1446 0 11.8902 0 11.625Z"
    />
  </svg>
</template>

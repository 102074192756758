<template>
  <svg
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 14.052C23.9438 10.9207 23.5739 7.7543 22.9674 4.55836C22.4733 2.33789 20.6958 0.360238 17.8556 0.215395C15.7589 0.129145 15.3535 1.32352 12.9778 1.30055C12.3272 1.29633 11.6771 1.29633 11.0264 1.30055C8.65033 1.32352 8.24393 0.129145 6.14815 0.215395C3.30752 0.360238 1.47471 2.33274 1.03455 4.55836C0.42752 7.7543 0.0576764 10.9202 0.00189513 14.0515C-0.0116986 16.2316 2.1408 17.682 3.56111 17.7813C6.30471 17.9885 8.4844 13.1505 10.1452 13.1501C11.3831 13.1571 12.6206 13.1576 13.8586 13.1501C15.5199 13.1501 17.6977 17.989 20.4431 17.7818C21.863 17.6824 24.0713 16.2223 24.0005 14.052H24ZM8.95033 7.94227H7.67064V9.22196C7.67064 9.76196 7.23283 10.1998 6.69283 10.1998C6.15283 10.1998 5.71502 9.76196 5.71502 9.22196V7.94227H4.43533C3.89533 7.94227 3.45752 7.50446 3.45752 6.96446C3.45752 6.42446 3.89533 5.98664 4.43533 5.98664H5.71502V4.70696C5.71502 4.16696 6.15283 3.72914 6.69283 3.72914C7.23283 3.72914 7.67064 4.16696 7.67064 4.70696V5.98664H8.95033C9.49033 5.98664 9.92815 6.42446 9.92815 6.96446C9.92815 7.50446 9.49033 7.94227 8.95033 7.94227ZM16.8521 10.1993C16.0866 10.2199 15.45 9.61524 15.4294 8.85024C15.4092 8.08243 16.0144 7.44493 16.7794 7.42524C17.5453 7.40602 18.1828 8.01024 18.2025 8.77618C18.2217 9.54211 17.6175 10.1796 16.8521 10.1993ZM19.1606 6.50321C18.3952 6.52477 17.7572 5.91961 17.7361 5.15414C17.7169 4.38727 18.3211 3.75071 19.0871 3.72961C19.8539 3.70946 20.4905 4.31461 20.5111 5.08055C20.5313 5.84602 19.9256 6.48399 19.1606 6.50321Z"
    />
    <defs>
      <linearGradient
        id="svg_gradient"
        x1="9.00065"
        y1="0"
        x2="9.00065"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE04A" />
        <stop
          offset="1"
          stop-color="#FF882D"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4235_2685)">
      <path
        d="M0 3H20V16.3333H0V3Z"
        fill="white"
      />
      <path
        d="M0 3H20V4.02534H0V3ZM0 5.05068H20V6.07602H0V5.05068ZM0 7.10136H20V8.12671H0V7.10136ZM0 9.15205H20V10.1774H0V9.15205ZM0 11.2066H20V12.232H0V11.2066ZM0 13.2573H20V14.2827H0V13.2573ZM0 15.308H20V16.3333H0V15.308Z"
        fill="#D80027"
      />
      <path
        d="M0 3H10V10.1774H0V3Z"
        fill="#2E52B2"
      />
      <path
        d="M1.86359 8.41513L1.70764 7.91611L1.5361 8.41513H1.02148L1.43864 8.71533L1.28269 9.21435L1.70764 8.90636L2.1209 9.21435L1.96106 8.71533L2.38601 8.41513H1.86359ZM4.05852 8.41513L3.89868 7.91611L3.73493 8.41513H3.22031L3.63747 8.71533L3.48152 9.21435L3.89868 8.90636L4.31973 9.21435L4.16378 8.71533L4.58094 8.41513H4.05852ZM6.26125 8.41513L6.09361 7.91611L5.93766 8.41513H5.41135L5.8402 8.71533L5.67646 9.21435L6.09361 8.90636L6.52246 9.21435L6.35872 8.71533L6.77587 8.41513H6.26125ZM8.45228 8.41513L8.29634 7.91611L8.1326 8.41513H7.61408L8.03513 8.71533L7.87918 9.21435L8.29634 8.90636L8.71739 9.21435L8.54975 8.71533L8.9786 8.41513H8.45228ZM3.89868 5.9356L3.73493 6.43463H3.22031L3.63747 6.74262L3.48152 7.23385L3.89868 6.92975L4.31973 7.23385L4.16378 6.74262L4.58094 6.43463H4.05852L3.89868 5.9356ZM1.70764 5.9356L1.5361 6.43463H1.02148L1.43864 6.74262L1.28269 7.23385L1.70764 6.92975L2.1209 7.23385L1.96106 6.74262L2.38601 6.43463H1.86359L1.70764 5.9356ZM6.09361 5.9356L5.93766 6.43463H5.41135L5.8402 6.74262L5.67646 7.23385L6.09361 6.92975L6.52246 7.23385L6.35872 6.74262L6.77587 6.43463H6.26125L6.09361 5.9356ZM8.29634 5.9356L8.1326 6.43463H7.61408L8.03513 6.74262L7.87918 7.23385L8.29634 6.92975L8.71739 7.23385L8.54975 6.74262L8.9786 6.43463H8.45228L8.29634 5.9356ZM1.70764 3.96289L1.5361 4.45412H1.02148L1.43864 4.76211L1.28269 5.25724L1.70764 4.94925L2.1209 5.25724L1.96106 4.76211L2.38601 4.45412H1.86359L1.70764 3.96289ZM3.89868 3.96289L3.73493 4.45412H3.22031L3.63747 4.76211L3.48152 5.25724L3.89868 4.94925L4.31973 5.25724L4.16378 4.76211L4.58094 4.45412H4.05852L3.89868 3.96289ZM6.09361 3.96289L5.93766 4.45412H5.41135L5.8402 4.76211L5.67646 5.25724L6.09361 4.94925L6.52246 5.25724L6.35872 4.76211L6.77587 4.45412H6.26125L6.09361 3.96289ZM8.29634 3.96289L8.1326 4.45412H7.61408L8.03513 4.76211L7.87918 5.25724L8.29634 4.94925L8.71739 5.25724L8.54975 4.76211L8.9786 4.45412H8.45228L8.29634 3.96289Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4235_2685">
        <rect
          y="3"
          width="20"
          height="13.3333"
          rx="1"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3833 8.6555C15.6286 8.6555 15.8492 8.80487 15.9403 9.03267L19.6709 18.359C19.8285 18.7531 19.5383 19.1818 19.1138 19.1818H18.3439C18.0986 19.1818 17.878 19.0325 17.7869 18.8048L16.864 16.4995C16.8184 16.3857 16.7081 16.311 16.5855 16.311H13.0778C12.9551 16.311 12.8448 16.3857 12.7992 16.4997L11.8781 18.8045C11.787 19.0324 11.5663 19.1818 11.3209 19.1818H10.5522C10.1277 19.1818 9.83746 18.7531 9.99507 18.359L13.7247 9.03271C13.8159 8.80489 14.0365 8.6555 14.2819 8.6555H15.3833ZM7.0555 1C7.38687 1 7.6555 1.26863 7.6555 1.6V2.91388H12.7971C13.1285 2.91388 13.3971 3.1825 13.3971 3.51388V4.22775C13.3971 4.55912 13.1285 4.82775 12.7971 4.82775H11.5139C10.7757 7.04983 9.59759 9.10049 8.04976 10.8574C8.61199 11.3591 9.213 11.8151 9.84703 12.2213C10.0963 12.381 10.2041 12.6937 10.0941 12.9686L9.82173 13.6498C9.68672 13.9874 9.28436 14.129 8.97595 13.9364C8.16846 13.4323 7.40636 12.858 6.69856 12.2201C5.14246 13.6284 3.32539 14.7145 1.35246 15.4184C1.0331 15.5323 0.689384 15.3446 0.598605 15.0179L0.406372 14.3261C0.320009 14.0153 0.49539 13.6931 0.798447 13.5826C2.47275 12.9719 4.0175 12.048 5.34928 10.8584C4.396 9.77918 3.58174 8.58581 2.92487 7.30628C2.79111 7.04573 2.98395 6.74163 3.27683 6.74163H4.43404C4.65422 6.74163 4.85589 6.86272 4.96525 7.05383C5.46192 7.92178 6.04241 8.73934 6.69856 9.49474C7.89484 8.11589 8.83761 6.53618 9.48325 4.82871L0.59994 4.82781C0.268592 4.82778 0 4.55916 0 4.22781V3.51388C0 3.1825 0.268629 2.91388 0.6 2.91388H5.74163V1.6C5.74163 1.26863 6.01026 1 6.34163 1H7.0555ZM15.1109 12.1127C15.0104 11.8612 14.6544 11.8611 14.5538 12.1125L13.804 13.9856C13.7251 14.1827 13.8702 14.3971 14.0825 14.3971H15.5809C15.7931 14.3971 15.9383 14.1828 15.8595 13.9858L15.1109 12.1127Z"
      fill="#E3E3E5"
    />
  </svg>
</template>

<template>
  <button
    :class="$style['close-button']"
    @click="coreStore.hideMenuHeader"
  >
    <IconClose />
  </button>
</template>

<script setup lang="ts">
const coreStore = useCoreStore();
</script>

<style lang="scss" module>
.close-button {
  @include safe-area-header;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
  border: none;
  outline: none;
  background-color: transparent;

  svg {
    width: 25px;
    height: 25px;
    fill: var(--general-white);
  }
}
</style>

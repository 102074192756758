<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4667 10.768C16.8164 10.768 17.1 11.0516 17.1 11.4013V17.3002C17.1 17.5477 16.9999 17.785 16.8218 17.96C16.6436 18.135 16.402 18.2333 16.15 18.2333H2.85C2.59804 18.2333 2.35641 18.135 2.17825 17.96C2.00009 17.785 1.9 17.5477 1.9 17.3002V11.4013C1.9 11.0516 2.18355 10.768 2.53333 10.768H16.4667ZM11.875 0.503193C12.4361 0.503005 12.9882 0.642305 13.4797 0.908121C13.9712 1.17394 14.3863 1.55762 14.6863 2.02344C14.9862 2.48926 15.1612 3.02207 15.1951 3.57224C15.2289 4.1224 15.1205 4.67204 14.8799 5.16995L18.05 5.16902C18.302 5.16902 18.5436 5.26733 18.7218 5.44234C18.8999 5.61734 19 5.85469 19 6.10218V8.07349C19 8.32098 18.8999 8.55833 18.7218 8.73333C18.5436 8.90834 18.302 9.00665 18.05 9.00665H0.95C0.698044 9.00665 0.456408 8.90834 0.278249 8.73333C0.100089 8.55833 0 8.32098 0 8.07349V6.10218C0 5.85469 0.100089 5.61734 0.278249 5.44234C0.456408 5.26733 0.698044 5.16902 0.95 5.16902L4.12015 5.16995C3.78172 4.47164 3.70655 3.67801 3.90802 2.93036C4.10949 2.1827 4.57452 1.52953 5.22034 1.08712C5.86616 0.644718 6.65086 0.441786 7.43473 0.514454C8.21861 0.587122 8.95079 0.930673 9.50095 1.48395C9.80973 1.17276 10.179 0.925616 10.5868 0.757151C10.9945 0.588685 11.4326 0.502327 11.875 0.503193ZM7.125 2.36952C6.75904 2.3697 6.40719 2.50817 6.14233 2.75624C5.87748 3.0043 5.71994 3.34295 5.70235 3.702C5.68477 4.06106 5.80848 4.413 6.04787 4.6849C6.28725 4.95679 6.62395 5.1278 6.9882 5.16249L7.125 5.16902H8.55V3.76927C8.54998 3.42131 8.41803 3.08583 8.17986 2.82825C7.9417 2.57067 7.6144 2.40946 7.2618 2.37606L7.125 2.36952ZM11.875 2.36952L11.7382 2.37606C11.4089 2.40705 11.1008 2.5496 10.8668 2.77929C10.6329 3.00897 10.4875 3.31146 10.4557 3.6349L10.45 3.76927V5.16902H11.875L12.0118 5.16249C12.3643 5.12892 12.6914 4.96764 12.9294 4.71008C13.1674 4.45251 13.2993 4.11712 13.2993 3.76927C13.2993 3.42142 13.1674 3.08603 12.9294 2.82847C12.6914 2.5709 12.3643 2.40962 12.0118 2.37606L11.875 2.36952Z"
    />
  </svg>
</template>

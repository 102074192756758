<template>
  <footer :class="$style['footer']">
    <div :class="$style['footer-inner-wrapper']">
      <div
        :class="$style['footer-inner-container']"
        data-test="footer-inner-container"
      >
        <Nav :class="$style['footer-nav']" />
        <Stats :class="$style['footer-stats']" />
        <IconBigLogo :class="$style['footer-logo']" />
        <Legal :class="$style['footer-legal']" />
        <Social :class="$style['footer-social']" />
      </div>
    </div>
    <div :class="$style['footer-picture']">
      <Case :class="$style['footer-picture-canvas']" />
    </div>
  </footer>
</template>

<script setup lang="ts">
import Legal from "./Footer/Legal.vue";
import Nav from "./Footer/Nav.vue";
import Stats from "./Footer/Stats.vue";
import Social from "./Footer/Social.vue";
import Case from "./Footer/Case.vue";
import platformEventTarget, {
  PLATFORM_IS_CHANGED,
} from "~/event-targets/platformEventTarget";

const settings = reactive({
  platform: usePlatform(),
});

provide("settings", settings);

const updateSettings = () => {
  settings.platform = usePlatform();
};

onBeforeMount(() => {
  platformEventTarget.addEventListener(PLATFORM_IS_CHANGED, updateSettings);
});

onBeforeUnmount(() => {
  platformEventTarget.removeEventListener(PLATFORM_IS_CHANGED, updateSettings);
});
</script>

<style lang="scss" module>
.footer {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: var(--general-underlay-1-lvl);
  border-radius: 20px 20px 0px 0px;
  color: var(--general-grey);

  &-picture {
    max-width: 569px;
    width: 30%;
    position: relative;

    &-canvas {
      position: absolute;
      width: 100%;
      min-width: 475px;
      max-width: 700px;
      bottom: 0;
    }
  }

  &-inner-wrapper {
    padding: 50px 60px;
    width: calc(100vw - 32.07%);
    min-width: 1130px;
    max-width: 1800px;
  }

  &-inner-container {
    display: grid;
    grid-template:
      "logo nav nav" 45px
      "stats stats stats" 45px
      "legal legal social" 45px / 136px auto 150px;
    grid-row-gap: 66px;
    grid-column-gap: 48px;
  }
  &-logo {
    grid-area: logo;
    fill: var(--general-grey);
    align-self: center;
  }
  &-legal {
    grid-area: legal;
    align-self: center;
  }
  &-nav {
    grid-area: nav;
    align-self: center;
  }
  &-stats {
    grid-area: stats;
  }
  &-social {
    grid-area: social;
    justify-self: end;
    align-self: center;
  }
  @media screen and (min-width: 1921px) {
    justify-content: center;
    &-picture {
      width: 569px;
    }
  }
  @media screen and (max-width: 1360px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-inner-wrapper {
      padding: 48px 62px 0;
      width: 100vw;
      min-width: unset;
    }
    &-inner-container {
      grid-template:
        "logo nav" 36px
        "legal nav" auto
        "stats stats" auto / 173px auto;
      grid-row-gap: 22px;
      grid-column-gap: 90px;
    }
    &-social {
      display: none;
    }
    &-legal {
      align-self: start;
    }
    &-stats {
      margin-top: 20px;
    }

    &-picture {
      margin-top: 32px;
      width: 475px;
      display: flex;
      &-canvas {
        position: static;
      }
    }
  }
  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-inner-wrapper {
      padding: 44px 41px 0;
      width: 100vw;
    }
    &-inner-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-row-gap: 0;
      grid-column-gap: 0;
    }
    &-nav {
      min-width: 100%;
    }
    &-stats {
      min-width: 100%;
      margin-top: 44px;
    }
    &-logo {
      margin-top: 48px;
      align-self: center;
      &:deep(svg) {
        fill: var(--general-grey);
      }
    }
    &-legal {
      margin-top: 26px;
      align-self: center;
    }
    &-social {
      display: none;
    }
  }

  @media screen and (max-width: 400px) {
    &-picture {
      &-canvas {
        min-width: auto;
      }
    }
  }

  @media screen and (max-width: 400px) {
    &-inner-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    &-picture {
      width: 375px;

      &-canvas {
        position: static;
      }
    }
  }

  @media screen and (max-width: 320px) {
    &-inner-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    &-picture {
      width: 320px;

      &-canvas {
        position: static;
      }
    }
  }
}

.footer {
  margin-top: 120px;
  @media screen and (max-width: 1800px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 1600px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 20px;
  }
}
</style>

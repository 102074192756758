<template>
  <button
    :class="[locale === 'ru' && $style.active, $style['localization-button']]"
    @click="handleClick('ru')"
  >
    <RussianFlagIcon /> Русский
  </button>
  <button
    :class="[locale === 'en' && $style.active, $style['localization-button']]"
    @click="handleClick('en')"
  >
    <EnglishFlagIcon /> English
  </button>
</template>

<script setup lang="ts">
import RussianFlagIcon from "~/components/Icon/RussianFlag.vue";
import EnglishFlagIcon from "~/components/Icon/EnglishFlag.vue";

const { locale, setLocale } = useI18n();
const coreStore = useCoreStore();
const handleClick = (value: string) => {
  setLocale(value);
  coreStore.hideLanguageSubmenu();
};
</script>

<style lang="scss" module>
.localization-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px 0px;
  padding-left: 24px;
  font-size: 0.875rem;
  color: var(--general-white);
  font-weight: 500;
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: none;
  gap: 8px;
  transition: 0.25s;

  &.active,
  &:hover {
    background: var(--button-ghost-active);
  }

  svg {
    transition: 0.25s;
  }
}
</style>

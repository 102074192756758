<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9369 8.84815C19.8944 8.74647 19.8336 8.65397 19.7561 8.57647L17.2567 6.07729C16.9309 5.75229 16.4042 5.75229 16.0784 6.07729C15.7525 6.40311 15.7525 6.93061 16.0784 7.25561L17.1559 8.33311H12.5006C12.0398 8.33311 11.6673 8.70643 11.6673 9.16643C11.6673 9.62643 12.0398 9.99975 12.5006 9.99975H17.1559L16.0783 11.0772C15.7525 11.4031 15.7525 11.9306 16.0783 12.2556C16.2408 12.4189 16.4542 12.4997 16.6675 12.4997C16.8809 12.4997 17.0942 12.4189 17.2567 12.2556L19.7561 9.75639C19.8336 9.67971 19.8944 9.58721 19.9369 9.48471C20.021 9.28147 20.021 9.05147 19.9369 8.84815Z"
    />
    <path
      d="M14.1674 11.6667C13.7066 11.6667 13.334 12.04 13.334 12.5V16.6666H10.0005V3.33331C10.0005 2.96581 9.75884 2.64082 9.40632 2.535L6.51199 1.66668H13.334V5.83335C13.334 6.29335 13.7066 6.66667 14.1674 6.66667C14.6282 6.66667 15.0008 6.29335 15.0008 5.83335V0.833358C15.0008 0.37332 14.6282 0 14.1674 0H0.833365C0.803363 0 0.776682 0.0125 0.74754 0.0158203C0.708358 0.02 0.672536 0.0266406 0.635034 0.0358202C0.547529 0.0583202 0.468345 0.0924998 0.394201 0.13914C0.37588 0.15082 0.353378 0.15164 0.335877 0.164961C0.329158 0.17 0.326658 0.179179 0.319978 0.184179C0.229153 0.25582 0.153329 0.34332 0.0983256 0.447499C0.0866453 0.469999 0.0841451 0.494179 0.075004 0.517499C0.0483229 0.580819 0.0191807 0.642499 0.00918018 0.712499C0.00500027 0.737499 0.0125007 0.760819 0.0116803 0.784999C0.01086 0.801678 0 0.816678 0 0.833319V17.5C0 17.8975 0.280835 18.2391 0.670036 18.3166L9.0038 19.9833C9.05799 19.995 9.11299 20 9.16713 20C9.35796 20 9.54547 19.9342 9.69548 19.8108C9.88799 19.6525 10.0005 19.4166 10.0005 19.1666V18.3333H14.1674C14.6282 18.3333 15.0008 17.96 15.0008 17.5V12.5C15.0008 12.04 14.6282 11.6667 14.1674 11.6667Z"
    />
  </svg>
</template>

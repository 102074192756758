<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6867 11.3262C14.3746 12.5181 13.2885 13.4003 12 13.4003C10.7115 13.4003 9.62542 12.5182 9.31333 11.3262H4.75769V20.4294H19.2423V11.3262H14.6867Z"
    />
    <path
      d="M12 9.25195C11.2439 9.25195 10.6288 9.86705 10.6288 10.6231C10.6288 11.3792 11.2439 11.9943 12 11.9943C12.7561 11.9943 13.3712 11.3791 13.3712 10.6231C13.3712 9.86705 12.7561 9.25195 12 9.25195Z"
    />
    <path
      d="M20.6482 3.63086V9.91998H24V7.62456C24 5.62839 22.5501 3.9647 20.6482 3.63086Z"
    />
    <path
      d="M0 7.62456V9.91998H3.35184V3.63086C1.44994 3.9647 0 5.62839 0 7.62456Z"
    />
    <path
      d="M4.75769 3.57031V9.92056H9.31333C9.62542 8.72858 10.7115 7.84644 12 7.84644C13.2885 7.84644 14.3746 8.72853 14.6867 9.92056H19.2423V3.57031H4.75769Z"
    />
    <path
      d="M20.6482 11.3262V20.4294H22.983C23.5438 20.4294 24 19.9732 24 19.4123V11.3262H20.6482Z"
    />
    <path
      d="M0 11.3262V19.4123C0 19.9731 0.456234 20.4294 1.01705 20.4294H3.35184V11.3262H0Z"
    />
    <defs>
      <linearGradient
        id="svg_gradient"
        x1="9.00065"
        y1="0"
        x2="9.00065"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE04A" />
        <stop
          offset="1"
          stop-color="#FF882D"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<template>
  <button
    data-test="menu_button_mobile"
    :class="$style['burger-button-mobile']"
  >
    <IconMenuMobile />
  </button>
</template>

<style lang="scss" module>
.burger-button-mobile {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  @media screen and (max-width: 1300px) {
    margin-left: 0;
  }
  @media screen and (max-width: 800px) {
    margin-left: 16px;
  }

  @media screen and (max-width: 475px) {
    margin-left: 10px;
  }

  &:hover {
    svg {
      fill: var(--bdrop-desktop-nav-hover);
    }
  }

  svg {
    width: 100%;
    fill: var(--general-white);
    transform: 0.25;
  }
}
</style>

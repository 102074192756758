<template>
  <div
    ref="containerRef"
    :class="[$style['side-panel'], stopped && $style.stopped]"
    :style="bottom"
  >
    <GameLinks />
  </div>
</template>

<script setup lang="ts">
import useScrollStopPosition from "./composables/useScrollStopPosition";
import GameLinks from "./GameLinks.vue";

const props = defineProps<{
  stopHeight: number;
}>();
const containerRef = ref<HTMLElement | null>(null);
const stopHeight = toRef(() => props.stopHeight + 60);
const stopped = useScrollStopPosition({
  elementRef: containerRef,
  bottom: stopHeight,
});
const bottom = computed(() =>
  stopped.value ? { bottom: `${stopHeight.value}px` } : { bottom: "unset" },
);
</script>

<style lang="scss" module>
.side-panel {
  position: fixed;
  top: 50%;
  top: max(200px, 50%);
  left: 16px;
  z-index: 10;
  height: 70vh;
  max-height: 480px;
  width: 64px;
  transform: translateY(-50%);

  @media screen and (max-height: 830px) {
    top: calc(50% + 100px);
  }

  &.stopped {
    position: absolute;
    top: unset;
    bottom: 450px;
    transform: none;
  }

  @media screen and (max-width: 1100px) {
    transform: translateX(-100%);
    opacity: 0;
  }
}
</style>

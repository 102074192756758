<template>
  <svg
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 8.75H2.33333C2.15 8.75 2 8.89062 2 9.0625V9.6875C2 9.85938 2.15 10 2.33333 10H11C11.1833 10 11.3333 9.85938 11.3333 9.6875V9.0625C11.3333 8.89062 11.1833 8.75 11 8.75ZM12.3333 2.5C11.7812 2.5 11.3333 2.91992 11.3333 3.4375C11.3333 3.57617 11.3667 3.70508 11.425 3.82422L9.91667 4.67188C9.59583 4.85156 9.18125 4.75 8.99583 4.44531L7.29792 1.66016C7.52083 1.48828 7.66667 1.23047 7.66667 0.9375C7.66667 0.419922 7.21875 0 6.66667 0C6.11458 0 5.66667 0.419922 5.66667 0.9375C5.66667 1.23047 5.8125 1.48828 6.03542 1.66016L4.3375 4.44531C4.15208 4.75 3.73542 4.85156 3.41667 4.67188L1.91042 3.82422C1.96667 3.70703 2.00208 3.57617 2.00208 3.4375C2.00208 2.91992 1.55417 2.5 1.00208 2.5C0.45 2.5 0 2.91992 0 3.4375C0 3.95508 0.447917 4.375 1 4.375C1.05417 4.375 1.10833 4.36719 1.16042 4.35938L2.66667 8.125H10.6667L12.1729 4.35938C12.225 4.36719 12.2792 4.375 12.3333 4.375C12.8854 4.375 13.3333 3.95508 13.3333 3.4375C13.3333 2.91992 12.8854 2.5 12.3333 2.5Z"
    />
  </svg>
</template>

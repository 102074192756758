<template>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5653 10.9934C12.5069 10.1627 11.9 8.94855 11.9 7.6622V5.85C11.9 3.56265 10.0702 1.6692 7.7 1.352V0.65C7.7 0.29055 7.3864 0 7 0C6.6136 0 6.3 0.29055 6.3 0.65V1.352C3.9291 1.6692 2.1 3.56265 2.1 5.85V7.6622C2.1 8.94855 1.4931 10.1627 0.4284 10.9986C0.1561 11.2151 0 11.5297 0 11.8625C0 12.4897 0.5495 13 1.225 13H12.775C13.4505 13 14 12.4897 14 11.8625C14 11.5297 13.8439 11.2151 13.5653 10.9934Z"
      fill=""
    />
    <path
      d="M7.11759 16C8.27741 16 9.24766 15.19 9.47053 14.1177H4.76465C4.98752 15.19 5.95777 16 7.11759 16Z"
      fill=""
    />
  </svg>
</template>

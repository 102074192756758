export const useReferralHandler = async () => {
  if (isServer()) return;
  let needRedirect = false;
  const route = useRoute();
  const userStore = useUserStore();
  const platform = usePlatform();

  if (route.query.ref) {
    localStorage.setItem("ref", route.query.ref.toString());
    if (userStore.userIsAuthorized) {
      await userStore.bindInternalReferral();
      userStore.clearStorageReferral();
      needRedirect = true;
    }
  } else if (localStorage.getItem("ref") && userStore.userIsAuthorized) {
    await userStore.bindInternalReferral();
    userStore.clearStorageReferral();
  }

  if (route.query.partner_id && route.query.offer_id) {
    localStorage.setItem("partner_id", route.query.partner_id.toString());
    localStorage.setItem("offer_id", route.query.offer_id.toString());
    if (route.query.click_id && typeof route.query.click_id === "string")
      localStorage.setItem("click_id", route.query.click_id);
    if (userStore.userIsAuthorized) await userStore.bindReferralData();
    needRedirect = true;
  }

  if (needRedirect) navigateTo(platform.basePath, { replace: true });
};

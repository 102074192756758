<template>
  <NavLink
    :to="settings?.platform.basePath || ''"
    :class="$style['big-logo-link']"
    :consider-platform="false"
  >
    <IconBigLogo />
  </NavLink>
</template>

<script setup lang="ts">
const settings = inject<{
  platform: ReturnType<typeof usePlatform>;
}>("settings");
</script>

<style lang="scss" module>
.big-logo-link {
  margin-right: 16px;
  svg {
    fill: var(--bdrop-logo);
  }
}
</style>

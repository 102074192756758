<template>
  <div :class="$style['mobile-language']">
    <button @click="coreStore.showLanguageSubmenuMobile">
      <IconLanguages /> {{ $t("header.select_language") }}
    </button>
  </div>
</template>

<script setup>
const coreStore = useCoreStore();
defineEmits("hideMenuHeader");
</script>

<style lang="scss" module>
.mobile-language {
  button,
  svg {
    transition: 0.25s;
  }

  button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 13px 0px;
    padding-left: 24px;
    font-size: 0.875rem;
    color: var(--general-white);
    font-weight: 500;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;

    &:hover {
      background: var(--button-ghost-active);
    }
  }
}
</style>

<template>
  <div :class="$style['desktop-language']">
    <div
      v-if="coreStore.languageSubmenuIsRequired"
      :class="$style['desktop-language-menu']"
      @click="coreStore.hideLanguageSubmenu"
    >
      <Locales />
    </div>
    <button @click="coreStore.toggleLanguageSubmenu">
      <IconLanguages /> {{ t("header.select_language") }}
    </button>
  </div>
</template>

<script setup>
import Locales from "./Locales.vue";

const coreStore = useCoreStore();
const { t } = useI18n();
</script>

<style lang="scss" module>
.desktop-language {
  position: relative;

  &-menu {
    padding: 16px 0;
    border-radius: 10px;
    background-color: var(--general-transparent-dark-75);
    width: 240px;
    position: absolute;
    left: -250px;
    top: -16px;
  }

  button,
  svg {
    transition: 0.25s;
  }

  button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100% !important;
    padding: 13px 0px;
    padding-left: 24px;
    font-size: 0.875rem;
    color: var(--general-white);
    font-weight: 500;
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
    gap: 0 !important;
    border-radius: 14px;

    &:hover {
      background: var(--button-ghost-active);
    }
  }
}
</style>

<template>
  <Container>
    <template #left-side>
      <Pc>
        <BigLogo />
        <PlatformSwitcherButton />
        <DesktopNav />
      </Pc>
      <Mobile>
        <SmallLogo />
        <PlatformSwitcherButton />
      </Mobile>
    </template>
    <template #right-side>
      <template v-if="userStore.visitorStatusIsResolved">
        <template v-if="userStore.userIsAuthorized">
          <PcAndTablet>
            <VolumeButton />
          </PcAndTablet>
          <PcAndTablet>
            <NotificationButton />
          </PcAndTablet>
          <BalanceButton />
          <PcAndTablet>
            <Avatar @click="coreStore.toggleMenuHeader" />
          </PcAndTablet>
          <Pc>
            <BurgerButton @click="coreStore.toggleMenuHeader" />
          </Pc>
        </template>
        <LoginButton v-else />
        <Mobile>
          <BurgerButtonMobile @click="coreStore.toggleMenuHeader" />
        </Mobile>
      </template>
    </template>
    <Transition
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <Menu
        v-if="coreStore.headerMenuIsRequired"
        :class="{ 'hidden-on-pc': !userStore.userIsAuthorized }"
      />
    </Transition>
  </Container>
</template>

<script setup lang="ts">
import animejs from "animejs";
import Container from "./Header/Container.vue";
import Pc from "./Header/Pc.vue";
import PcAndTablet from "./Header/PcAndTablet.vue";
import Mobile from "./Header/Mobile.vue";
import NotificationButton from "./Header/NotificationButton.vue";
import VolumeButton from "./Header/VolumeButton.vue";
import BalanceButton from "./Header/BalanceButton.vue";
import Avatar from "./Header/Avatar.vue";
import BurgerButton from "./Header/BurgerButton.vue";
import BurgerButtonMobile from "./Header/BurgerButtonMobile.vue";
import BigLogo from "./Header/BigLogo.vue";
import SmallLogo from "./Header/SmallLogo.vue";
import DesktopNav from "./Header/DesktopNav.vue";
import Menu from "./Header/Menu.vue";
import LoginButton from "./Header/LoginButton.vue";
import PlatformSwitcherButton from "./PlatformSwitcher/PlatformSwitcherButton.vue";
import platformEventTarget, {
  PLATFORM_IS_CHANGED,
} from "~/event-targets/platformEventTarget";

const breakpoint: number = 1300;
const userStore = useUserStore();
const coreStore = useCoreStore();

function beforeEnter(el: Element) {
  if (document.documentElement.clientWidth >= breakpoint) {
    (el as HTMLElement).style.opacity = "0";
    (el as HTMLElement).style.transform = "translateY(20px)";
  } else {
    (el.children[0] as HTMLElement).style.transform = "translateX(-100%)";
  }
}

function enter(el: Element, done: () => void) {
  if (document.documentElement.clientWidth >= breakpoint) {
    animejs({
      targets: el,
      opacity: 1,
      translateY: 0,
      duration: 250,
      easing: "easeOutExpo",
      complete: done,
    });
  } else {
    animejs({
      targets: el.children[0],
      translateX: 0,
      duration: 250,
      easing: "easeOutExpo",
      complete: done,
    });
  }
}

function leave(el: Element, done: () => void) {
  if (document.documentElement.clientWidth >= breakpoint) {
    animejs({
      targets: el,
      opacity: 0,
      translateY: 20,
      duration: 250,
      easing: "easeOutExpo",
      complete: done,
    });
  } else {
    animejs({
      targets: el.children[0],
      translateX: "-100%",
      duration: 250,
      easing: "easeOutExpo",
      complete: done,
    });
  }
}

const settings = reactive({
  platform: usePlatform(),
});

provide("settings", settings);

const updateSettings = () => {
  settings.platform = usePlatform();
};

onMounted(() => {
  platformEventTarget.addEventListener(PLATFORM_IS_CHANGED, updateSettings);
});

onBeforeUnmount(() => {
  platformEventTarget.removeEventListener(PLATFORM_IS_CHANGED, updateSettings);
});
</script>

<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="9.72152"
      height="9.72152"
      rx="1"
      fill="#E3E3E5"
    />
    <path
      d="M9.11431 12.0001H4.25355C4.00089 11.9968 3.79783 11.791 3.79785 11.5383C3.80118 11.289 4.00423 11.0887 4.25355 11.0887H9.11431C9.63908 11.0887 10.1423 10.8798 10.5128 10.5082C10.8833 10.1366 11.0906 9.63273 11.089 9.10796V4.2472C11.089 3.99553 11.293 3.7915 11.5447 3.7915C11.7964 3.7915 12.0004 3.99553 12.0004 4.2472V9.10796C12.0004 10.7029 10.7092 11.9968 9.11431 12.0001Z"
      fill="#E3E3E5"
    />
  </svg>
</template>

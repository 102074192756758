<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1074_2166)">
      <path
        d="M17.8883 9.99999L19.7254 8.16292C20.0914 7.79679 20.0914 7.20323 19.7254 6.8371C19.3592 6.47097 18.7657 6.47097 18.3995 6.8371L16.5625 8.67417L14.7254 6.8371C14.3593 6.47097 13.7657 6.47097 13.3996 6.8371C13.0335 7.20323 13.0335 7.79679 13.3996 8.16292L15.2366 9.99999L13.3996 11.8371C13.0335 12.2032 13.0335 12.7967 13.3996 13.1629C13.5826 13.346 13.8225 13.4375 14.0625 13.4375C14.3024 13.4375 14.5423 13.346 14.7254 13.1629L16.5625 11.3258L18.3995 13.1629C18.5826 13.346 18.8225 13.4375 19.0625 13.4375C19.3024 13.4375 19.5423 13.346 19.7254 13.1629C20.0915 12.7968 20.0915 12.2032 19.7254 11.8371L17.8883 9.99999Z"
      />
      <path
        d="M3.86719 6.25H1.71875C0.769492 6.25 0 7.01949 0 7.96875V12.0312C0 12.9805 0.769492 13.75 1.71875 13.75H3.86719C3.97504 13.75 4.0625 13.6625 4.0625 13.5547V6.44531C4.0625 6.33746 3.97504 6.25 3.86719 6.25Z"
      />
      <path
        d="M10.9375 2.1875H10C9.79418 2.1875 9.59406 2.25523 9.43055 2.38027L5.38918 5.47074C5.34086 5.5077 5.3125 5.56504 5.3125 5.6259V14.3741C5.3125 14.435 5.34086 14.4923 5.38918 14.5293L9.43055 17.6197C9.59406 17.7448 9.79418 17.8125 10 17.8125H10.9375C11.4553 17.8125 11.875 17.3928 11.875 16.875V3.125C11.875 2.60723 11.4553 2.1875 10.9375 2.1875Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1074_2166">
        <rect
          width="20"
          height="20"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <div
    :class="$style['social-links-container']"
    data-test="social-links-container"
  >
    <ClientOnly>
      <a
        :href="vk"
        target="__blank"
        :class="$style['social-link']"
      >
        <IconVk />
      </a>
      <a
        :href="settings?.platform.tg"
        target="__blank"
        :class="$style['social-link']"
      >
        <IconTelegram />
      </a>
      <a
        :href="discord"
        target="__blank"
        :class="$style['social-link']"
      >
        <IconDiscord2 />
      </a>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
const { vk, discord } = useCoreStore();
const settings = inject<{
  platform: ReturnType<typeof usePlatform>;
}>("settings");
</script>

<style lang="scss" module>
.social-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  gap: 16px;
}

.social-link {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: $br10;
  background-color: transparent;
  transition: 0.25s;

  &:hover {
    background-color: var(--button-ghost-hover);
  }

  svg {
    width: 20px;
    fill: var(--bdrop-footer-nav-color);
  }
}
</style>

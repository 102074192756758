<template>
  <svg
    :class="$style.logo"
    viewBox="0 0 33 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2912 16.6864C26.318 17.6026 27.1164 18.9776 27.1164 20.8864V21.2681C27.1164 25.2395 23.732 27.4542 19.9288 27.4542H7.684C6.6572 27.4542 5.82012 26.6138 5.82012 25.6211C5.82012 24.6284 6.6572 23.7505 7.684 23.7505H19.9668C22.1347 23.7505 23.0853 22.4533 23.0853 21.2681V20.8864C23.0853 19.7026 22.1333 18.3665 19.9668 18.3665H17.6467C17.404 18.3712 17.1628 18.3273 16.9372 18.2373C16.7117 18.1473 16.5062 18.0131 16.3329 17.8424C16.1596 17.6718 16.022 17.4681 15.928 17.2434C15.834 17.0187 15.7856 16.7775 15.7856 16.5338C15.7856 16.2901 15.834 16.0488 15.928 15.8241C16.022 15.5994 16.1596 15.3958 16.3329 15.2251C16.5062 15.0545 16.7117 14.9202 16.9372 14.8302C17.1628 14.7402 17.404 14.6963 17.6467 14.701H20.0049C22.1347 14.701 23.0853 13.4038 23.0853 12.2186V11.837C23.0853 10.6532 22.1333 9.27884 20.0423 9.27884H4.25952C1.904 9.27884 0 7.21635 0 4.88759C0 2.02358 2.89068 0 4.22144 0C4.44992 0 4.60156 0.0764646 4.60156 0.191162C4.60156 0.532521 3.64956 1.71841 3.64956 3.16919C3.64956 4.61996 4.63964 5.61264 5.81876 5.61264H26.5084C27.6869 5.61264 28.6756 4.61996 28.6756 3.16919C28.6756 1.71841 27.7236 0.53457 27.7236 0.191162C27.7236 0.0764646 27.8759 0 28.1037 0C29.4352 0 32.3258 2.02358 32.3258 4.88759C32.3258 7.21635 30.4218 9.27884 28.0663 9.27884H26.622C26.5084 9.27884 26.4704 9.35531 26.5084 9.46932C26.9028 10.2372 27.111 11.0877 27.1164 11.9517V12.2931C27.1164 13.5902 26.6601 15.0806 25.2912 16.3027L25.2151 16.3784C25.1389 16.4549 25.177 16.5696 25.2532 16.6461L25.2912 16.6864Z"
    />
  </svg>
</template>

<style lang="scss" module>
.logo {
  fill: var(--bdrop-logo);
}
</style>

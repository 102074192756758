<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4235_1087)">
      <path
        d="M0 3V16.3333H20V3H0Z"
        fill="white"
      />
      <path
        d="M0 3H20V16.3335H0V3Z"
        fill="#0052B4"
      />
      <path
        d="M0 3H20V7.44434H0V3Z"
        fill="white"
      />
      <path
        d="M0 11.8887H20V16.333H0V11.8887Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_4235_1087">
        <rect
          y="3"
          width="20"
          height="13.3333"
          rx="1"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 7.36842C5 4.46166 7.23858 2.10526 10 2.10526C12.7614 2.10526 15 4.46166 15 7.36842V14.7368C15 16.4809 13.6569 17.8947 12 17.8947H9.75C9.19771 17.8947 8.75 18.366 8.75 18.9474C8.75 19.5287 9.19772 20 9.75 20H12C14.7614 20 17 17.6436 17 14.7368C18.6569 14.7368 20 13.323 20 11.5789V9.47369C20 7.72963 18.6569 6.31579 17 6.31579H16.9291C16.4439 2.74495 13.5265 0 10 0C6.47353 0 3.55612 2.74495 3.07089 6.31579H3C1.34315 6.31579 0 7.72963 0 9.47369V11.5789C0 13.323 1.34315 14.7368 3 14.7368H5V7.36842Z"
    />
  </svg>
</template>
